<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择品牌"
    class="small-padding"
    :modal="false"
    width="1000px"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/routing-inspection/dicts/group">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.type','字典分组名称')">
            <el-input v-model.trim="filter.name" type="userType"></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="dictSetName" label="字典集名称" align="center"></el-table-column>
      <el-table-column prop="name" label="字典分组名称" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          customerName: "",
          manufacturerName: "",
          status: "",
        },
      };
    },
    methods: {
      open(name) {
        this.filter={};
        this.dialogVisible = true;
        if (name) this.filter.manufacturerName=name;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
