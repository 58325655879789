<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/routing-inspection/dicts/group">

      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.type','字典集名称')">
            <el-input v-model.trim="filter.dictSetName"></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','字典分组名称')">
            <el-input v-model.trim="filter.name"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="dictSetName" label="字典集名称" align="center"></el-table-column>
      <el-table-column prop="name" label="字典分组名称" align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
  export default {

    components: {},
    props:{
      query:Object,
    },
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          faultCode:"",
          status: "",
        },
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible =false;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/routing-inspection/dict-set/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
