<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/routing-inspection/dicts/page">
      <el-button
        v-if="$auth('电梯巡检字典新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>

      <el-button
        v-if="$auth('电梯巡检字典导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.type','字典集名称')">
            <el-input v-model.trim="filter.dictSetName"></el-input>
          </vm-search>
          <vm-search :label="$l('user.type','字典分组名称')">
            <el-input v-model.trim="filter.dictGroupName"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="dictSetName" label="字典集名称" align="center"></el-table-column>
      <el-table-column prop="dictGroupName" label="字典分组名称" align="center"></el-table-column>
      <el-table-column prop="content" label="内容" align="center"></el-table-column>
      <el-table-column prop="requirement" label="要求" align="center"></el-table-column>
      <el-table-column prop="needToPhoto" label="是否需要拍照" align="center">
        <template slot-scope="scope">
          {{scope.row.needToPhoto?"是":"否"}}
        </template>
      </el-table-column>

      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('电梯巡检字典编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('电梯巡检字典删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/routingInspectionDict.xlsx"
        action="basic/routing-inspection/dicts/import"
        name="电梯巡检字典导入"
        download-name="电梯巡检字典模板.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./DictEdit";

  export default {

    components: {EditPage},
    props:{
      query:Object,
    },
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          faultCode:"",
          status: "",
        },
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible =false;
      },
      deleteRow(row) {
        this.$confirm("确定删除该字典吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/routing-inspection/dict/", row.id).then(() => {
            this.getList(-1);
            this.$message.success("字典" + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
