<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('电梯巡检字典集')" label="字典集" name="first"><dict-set ref="dictset"></dict-set></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯巡检字典分组')" label="字典分组" name="second"><dict-group ref="dictgroup"></dict-group></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯巡检字典条目')" label="字典条目" name="third"><dict ref="dict"></dict></el-tab-pane>
  </el-tabs>
</template>

<script>
  import DictSet from "@/views/basic/DictSet";
  import DictGroup from "@/views/basic/DictGroup";
  import Dict from "@/views/basic/Dict";
  export default {
    name: "RoutingInspection",
    components:{DictSet,DictGroup,Dict},
    methods: {
      handleClick(tab) {
        if (tab.name==="first") {
          this.$refs.dictset.getList(1);
        }else if (tab.name==="second") {
          this.$refs.dictgroup.getList(1);
        }else if (tab.name==="third") {
          this.$refs.dict.getList(1);
        }
      },
    },
  };
</script>

<style scoped>

</style>